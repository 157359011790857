// src/components/ChatWindow.jsx
import React, { useEffect, useRef } from 'react';
import Message from './Message';
import './ChatWindow.css';
import { ClipLoader } from 'react-spinners';

const ChatWindow = ({ messages, loading }) => {
  const chatEndRef = useRef(null);

  // Scroll to the bottom whenever messages or loading state changes
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, loading]);

  return (
    <div className="chat-window" role="log" aria-live="polite" aria-relevant="additions text">
      {messages.map((msg, index) => (
        <Message
          key={index}
          sender={msg.sender}
          text={msg.text}
          timestamp={msg.timestamp}
        />
      ))}
      {loading && (
        <div className="message bot loading-message" role="status" aria-live="polite">
          <ClipLoader size={20} color="#5E99BD" />
        </div>
      )}
      <div ref={chatEndRef} />
    </div>
  );
};

export default ChatWindow;
