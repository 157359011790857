// src/components/StudyGuide.jsx
import React, { useState, useEffect } from 'react';
import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import { sendStudyGuideRequest } from '../services/api';
import './StudyGuide.css';

const StudyGuide = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');

  // Initialize User ID
  useEffect(() => {
    const getUserIdFromCookies = () => {
      const match = document.cookie.match(new RegExp('(^| )user_id=([^;]+)'));
      if (match) return match[2];
      return null;
    };

    let id = getUserIdFromCookies();
    if (!id) {
      id = `user_${Date.now()}`;
      document.cookie = `user_id=${id}; path=/; secure; samesite=none`;
    }
    setUserId(id);
  }, []);

  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const userMessage = { sender: 'user', text: input, timestamp };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setLoading(true);

    try {
      const data = await sendStudyGuideRequest(input, userId);
      const botMessage = { sender: 'bot', text: data.response || 'No response from AI.', timestamp };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = { sender: 'bot', text: 'Sorry, something went wrong. Please try again later.', timestamp };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chat-container">
      <div className={`chat-content ${messages.length === 0 ? 'centered' : ''}`}>
        <div className="instruction">
          <h2>Study Guide Creator</h2>
          <p>Enter the topic for your study guide below</p>
        </div>
        {(messages.length > 0 || loading) && (
          <ChatWindow messages={messages} loading={loading} />
        )}
      </div>
      <ChatInput input={input} setInput={setInput} sendMessage={handleSendMessage} />
    </div>
  );
};

export default StudyGuide;
