// src/components/Chat.jsx

import React, { useState, useEffect } from 'react';
import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import { sendMessage } from '../services/api';
import './Chat.css';
import axios from 'axios';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [subscriptionLevel, setSubscriptionLevel] = useState('No subscription');
  const [freeRequestsRemaining, setFreeRequestsRemaining] = useState(10);
  const [error, setError] = useState(null);

  // Function to check authentication status
  const checkAuthentication = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');

    if (!accessToken || !userId) {
      setIsAuthenticated(false);
      return;
    }

    try {
      const response = await axios.get('https://api.apexvision.ai/subscription_status', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'UserId': userId,
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        setIsAuthenticated(true);
        setSubscriptionLevel(response.data.subscription_level);
        setFreeRequestsRemaining(response.data.free_requests_remaining);
      } else {
        setIsAuthenticated(false);
      }
    } catch (err) {
      console.error('Error fetching subscription status:', err);
      setError('Failed to fetch subscription status.');
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const userMessage = { sender: 'user', text: input, timestamp };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setLoading(true);
    setError(null);

    try {
      const data = await sendMessage(input);
      const botMessage = { sender: 'bot', text: data.response || 'No response from AI.', timestamp };
      setMessages((prev) => [...prev, botMessage]);
      // Update free requests remaining if applicable
      if (subscriptionLevel === 'No subscription') {
        setFreeRequestsRemaining((prev) => prev - 1);
      }
    } catch (err) {
      console.error('Error sending message:', err);
      if (err.response && err.response.status === 429) {
        setError('Request limit exceeded. Upgrade for more requests.');
      } else {
        setError('An error occurred while sending your message.');
      }
      const errorMessage = {
        sender: 'bot',
        text: 'Sorry, something went wrong. Please try again later.',
        timestamp,
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginClick = () => {
    const clientId = 'gkYwCIVHBiEMacxfdIHyXzpEnbqttPpH';
    const redirectUri = encodeURIComponent('https://chat.apexvision.ai/auth-callback');

    const authUrl = `https://apexvision.ai/wp-json/moserver/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;

    window.location.href = authUrl;
  };

  const handleLogout = async () => {
    try {
      // Notify the backend to invalidate the session and clear cookies
      await axios.post('https://api.apexvision.ai/logout', {}, { withCredentials: true });

      // Clear stored tokens
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');

      // Update frontend authentication state
      setIsAuthenticated(false);
      setSubscriptionLevel('No subscription');
      setFreeRequestsRemaining(10);
      setMessages([]);
    } catch (err) {
      console.error('Error during logout:', err);
      setError('Failed to log out. Please try again.');
    }
  };

  return (
    <div className="chat-container">
      <div
        className="header"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 20px',
        }}
      >
        <h2>Chat Assistant</h2>
        {isAuthenticated && (
          <button
            onClick={handleLogout}
            style={{
              padding: '5px 10px',
              backgroundColor: '#d9534f',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Logout
          </button>
        )}
      </div>
      {!isAuthenticated ? (
        <div
          style={{
            textAlign: 'center',
            fontFamily: "'Rubik', monospace",
            margin: '50px auto',
          }}
        >
          <p style={{ fontSize: '16px', margin: '20px 0' }}>
            Please log in to start using the chat. Visit the{' '}
            <a
              href="https://apexvision.ai/user-guide/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#007bff' }}
            >
              User Guide
            </a>{' '}
            to explore all features.
          </p>
          <button
            onClick={handleLoginClick}
            style={{
              display: 'inline-block',
              padding: '10px 20px',
              backgroundColor: '#5E99BD',
              color: '#f7f7f7',
              border: '1px solid #A390E4',
              borderRadius: '5px',
              cursor: 'pointer',
              fontWeight: '600',
              fontFamily: "'Rubik', monospace",
              textTransform: 'uppercase',
              fontSize: '16px',
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#6CCFF6')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = '#5E99BD')}
          >
            Login
          </button>
          <p style={{ fontSize: '16px', marginTop: '20px' }}>
            You have 10 Free Requests. Purchase a subscription at{' '}
            <a
              href="https://apexvision.ai"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#007bff' }}
            >
              Apexvision.ai
            </a>{' '}
            if you like it.
          </p>
        </div>
      ) : (
        <>
          <div className={`chat-content ${messages.length === 0 ? 'centered' : ''}`}>
            <div className="instruction">
              <h3>Subscription Level: {subscriptionLevel}</h3>
              {subscriptionLevel === 'No subscription' && (
                <h4>Free Requests Remaining: {freeRequestsRemaining}</h4>
              )}
            </div>
            {error && (
              <div style={{ color: 'red', textAlign: 'center', marginBottom: '10px' }}>
                {error}
              </div>
            )}
            {(messages.length > 0 || loading) && (
              <ChatWindow messages={messages} loading={loading} />
            )}
          </div>
          <ChatInput
            input={input}
            setInput={setInput}
            sendMessage={handleSendMessage}
            disabled={
              loading ||
              (subscriptionLevel === 'No subscription' && freeRequestsRemaining <= 0)
            }
          />
        </>
      )}
    </div>
  );
};

export default Chat;
