// src/services/api.js

import axios from 'axios';

// Define the API base URL based on the environment
const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.apexvision.ai'; // Default to production if not set

// Helper function to handle fetch requests using Axios
const handleFetch = async (endpoint, message) => {
  const accessToken = localStorage.getItem('accessToken');
  const userId = localStorage.getItem('userId');

  try {
    const response = await axios.post(`${API_BASE_URL}${endpoint}`, { userInput: message }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'UserId': userId,
      },
      withCredentials: true, // Ensure cookies (session) are included for authentication
    });

    return response.data;
  } catch (error) {
    console.error(`Error in ${endpoint}:`, error);
    throw error;
  }
};

// Exported API functions
export const sendMessage = async (message) => {
  return handleFetch('/regular_chat', message);
};

export const sendEssayRequest = async (message) => {
  return handleFetch('/essay_writing_module', message);
};

export const sendCodingRequest = async (message) => {
  return handleFetch('/coding_module', message);
};

export const sendFlashcardsRequest = async (message) => {
  return handleFetch('/flashcards_module', message);
};

export const sendMathRequest = async (message) => {
  return handleFetch('/math_module', message);
};

export const sendStudyGuideRequest = async (message) => {
  return handleFetch('/study_guide_module', message);
};
