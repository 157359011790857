// src/components/ChatInput.jsx
import React, { useEffect, useRef } from 'react';
import './ChatInput.css';

const ChatInput = ({ input, setInput, sendMessage }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className="chat-input-area">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type your message here..."
        className="chat-input"
        aria-label="Message input"
        ref={inputRef}
      />
      <button
        onClick={sendMessage}
        className="send-button"
        aria-label="Send message"
      >
        Send
      </button>
    </div>
  );
};

export default ChatInput;
