// src/components/MainContent.jsx

import React from 'react';
import Chat from './Chat';
import EssayWriting from './EssayWriting';
import Coding from './Coding';
import Flashcards from './Flashcards';
import MathTool from './MathTool';
import StudyGuide from './StudyGuide';
import './MainContent.css';

const MainContent = ({ activeTab }) => {
  const renderContent = () => {
    switch (activeTab) {
      case 'Chat':
        return <Chat />;
      case 'Essay Writing':
        return <EssayWriting />;
      case 'Coding':
        return <Coding />;
      case 'Flashcards':
        return <Flashcards />;
      case 'Math':
        return <MathTool />;
      case 'Study Guide':
        return <StudyGuide />;
      default:
        return <Chat />;
    }
  };

  return <div className="main-content">{renderContent()}</div>;
};

export default MainContent;
