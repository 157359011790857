// src/components/Sidebar.jsx
import React from 'react';
import './Sidebar.css';

const Sidebar = ({ activeTab, setActiveTab, toggleDarkMode, isDarkMode }) => {
  const tabs = ["Chat", "Math", "Study Guide", "Flashcards", "Essay Writing", "Coding"];

  return (
    <div className="sidebar">
      <h2 className="sidebar-title">Apex Chat</h2>
      <ul className="sidebar-tabs">
        {tabs.map((tab) => (
          <li
            key={tab}
            className={`sidebar-tab ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </li>
        ))}
      </ul>
      <button className="dark-mode-toggle" onClick={toggleDarkMode}>
        {isDarkMode ? 'Light Mode' : 'Dark Mode'}
      </button>
    </div>
  );
};

export default Sidebar;
