// src/components/EssayWriting.jsx

import React, { useState, useEffect } from 'react';
import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import { sendEssayRequest } from '../services/api';
import './EssayWriting.css';

const EssayWriting = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);

  // Check if user is authenticated
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');

    if (!accessToken || !userId) {
      // Redirect to login or handle unauthenticated state
      window.location.href = '/';
    }
  }, []);

  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const userMessage = { sender: 'user', text: input, timestamp };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setLoading(true);

    try {
      const data = await sendEssayRequest(input);
      const botMessage = { sender: 'bot', text: data.response || 'No response from AI.', timestamp };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = {
        sender: 'bot',
        text: 'Sorry, something went wrong. Please try again later.',
        timestamp,
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chat-container">
      <div className={`chat-content ${messages.length === 0 ? 'centered' : ''}`}>
        <div className="instruction">
          <h2>Essay Writing Assistant</h2>
          <p>Enter your essay topic or text below</p>
        </div>
        {(messages.length > 0 || loading) && (
          <ChatWindow messages={messages} loading={loading} />
        )}
      </div>
      <ChatInput input={input} setInput={setInput} sendMessage={handleSendMessage} />
    </div>
  );
};

export default EssayWriting;
